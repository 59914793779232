<template>
  <div
    v-loading="$store.getters['user/userLoad']"
    class="px-md-3 col just_for_showing_one wrapper_for_sharing_tasks"
  >
    <card class="card-user position-relative">
      <!-- //? start user photo  -->
      <div class="user_bg_img" slot="image">
        <!-- <div class=""> -->
        <img
          class="d-none"
          src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
          alt="..."
        />
        <div class="new_user_background"></div>
        <!-- </div> -->
      </div>
      <!-- //? end user photo  -->

      <div>
        <div class="author">
          <img class="avatar border-dark" :src="image" alt="..." />
        </div>
        <div class="wrapping_of_some_info text-center">
          <div class="text-capitalize">
            {{ user.first_name + " " + user.last_name }}
          </div>
          <div class="mt-2 mb-2">
            {{ user.email }}
          </div>
          <div class="learner_type" v-if="user.learner != null">
            {{ user.learner }}
          </div>
        </div>
      </div>

      <!-- //? Score Cards  -->
      <!--//  Medium & Small Screens  -->
      <div class="my-4 d-lg-none">
        <div class="d-flex justify-content-center flex-column">
          <!-- Sequence  -->
          <div class="d-flex align-items-center">
            <p class="label sequence">
              {{ userScore.sequence != null ? userScore.sequence : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              $t("patterns.sequence")
            }}</span>
          </div>
          <!-- Precision  -->
          <div class="d-flex align-items-center">
            <p class="label precision">
              {{ userScore.precision != null ? userScore.precision : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              $t("patterns.precision")
            }}</span>
          </div>
          <!-- Technical Reasoning  -->
          <div class="d-flex align-items-center">
            <p class="label technical_reasoning flex-shrink-0">
              {{
                userScore.technical_reasoning != null
                  ? userScore.technical_reasoning
                  : 0
              }}
            </p>
            <span class="main_words_of_scores">{{
              $t("patterns.Technical Reasoning")
            }}</span>
          </div>
          <!-- Confluence  -->
          <div class="d-flex align-items-center">
            <p class="label confluence">
              {{ userScore.confluence != null ? userScore.confluence : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              $t("patterns.confluence")
            }}</span>
          </div>
        </div>
      </div>
      <!--// Medium & Small Screens  -->

      <!-- //? Large Screens  -->
      <div class="my-4 d-none d-lg-block">
        <div class="col-sm-12 px-0 d-flex align-items-center">
          <!-- Sequence  -->
          <card
            :height="`${userScore.sequence * 2.5 + 120}px`"
            style="
              background-color: #39c;
              border-radius: 0;
              border: 1px solid #ccc;
              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->

              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ $t("patterns.sequence") }}
              </p>
              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">{{ $t("patterns.S") }}</p>
                <!-- Score Number  -->
                <!-- Small & medium Screens  -->

                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.sequence != null ? userScore.sequence : 0 }}
                </p>
              </div>
            </div>
          </card>
          <!-- Precision   -->
          <card
            :height="`${userScore.precision * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->

              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ $t("patterns.precision") }}
              </p>

              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">{{ $t("patterns.P") }}</p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.precision != null ? userScore.precision : 0 }}
                </p>
              </div>
            </div>
          </card>
          <!-- Technical Reasoning  -->
          <card
            :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #f93;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 1.4rem"
              >
                {{ $t("patterns.Technical Reasoning") }}
              </p>
              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                  flex-column flex-md-row
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">{{ $t("patterns.T") }}</p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize:
                      (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{
                    userScore.technical_reasoning != null
                      ? userScore.technical_reasoning
                      : 0
                  }}
                </p>
              </div>
            </div>
          </card>
          <!-- Confluence  -->
          <card
            :height="`${userScore.confluence * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #c36;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ $t("patterns.confluence") }}
              </p>

              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">{{ $t("patterns.C") }}</p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.confluence != null ? userScore.confluence : 0 }}
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- //? Large Screens  -->
      <!-- Start Share Print -->
      <div class="under_words">
        <div class="icons_wrapping_edit_print">
          <el-tooltip
            class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
            content="Print Member Profile"
            placement="top-start"
          >
            <el-button
              @click="printprofilePage"
              type="success"
              class="mb-2 mr-2 ml-2 edit-btn print-btn "
              ><i class="fa fa-print"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            class=" mb-2 mr-2 ml-2 edit-btn print-btn edit_score_button main_tool_tip"
            content="Share Member Profile"
            placement="top-start"
          >
            <el-button
              type="success"
              class="mb-2 "
              @click="open_share_profile_dialog"
              ><i class="fa fa-share-alt icons_actions "></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- End Share Print -->
      <!-- START USER ATTRIBUTES  -->
      <el-collapse class="mt-5" v-model="panel" v-if="userAttributes">
        <div class="child" v-for="(atrr, i) in userAttributes" :key="i">
          <el-collapse-item :name="`${i}`" class="">
            <!-- //! start  collapse title -->
            <template #title>
              <div
                class="main_collapse_title_wrapper"
                :class="{ is_rtl: isRtl() }"
                style="width: 100%"
              >
                <div>
                  <h4 class="every_collapse_title">
                    <i
                      :class="`fa fa-star mr-1 ${i}`"
                      :style="`color: ${getProgressColor(i)}`"
                    ></i>
                    {{ localization(i) }}
                  </h4>
                </div>
              </div>
            </template>
            <!-- //! end  collapse title -->
            <div>
              <el-collapse class="pt-3 pl-lg-4">
                <span
                  class="span_attr_type d-block"
                  :class="{ 'text-right': isRtl() }"
                  >{{ localization(atrr[0].type) }}</span
                >
                <l-alert
                  :style="
                    `opacity:0.8;background-color : ${getProgressColor(i)}`
                  "
                  class="mb3 mt-2 alert_for_collapse"
                  :class="{ 'text-right': isRtl() }"
                >
                  <span>
                    <b> {{ localization(atrr[0].type) }} - </b>
                    {{ getHint(i, atrr[0].type) }}
                  </span>
                </l-alert>

                <div
                  class="child text_to_speech_container my-2"
                  v-for="(item, i) in atrr"
                  :key="i"
                  :class="{ 'flex-row-reverse': isRtl() }"
                >
                  <!-- {{ selected }}
                  <el-checkbox
                    :label="item.id"
                    :class="{
                      'd-none': item.title < 1,
                      'flex-row-reverse': isRtl(),
                      'text-right': isRtl()
                    }"
                  >
                    {{ item.title }}
                  </el-checkbox> -->
                  <!-- class="" v-if="item" v-model="selected" -->
                  <!-- @change="select" -->
                  <el-checkbox-group v-if="item" v-model="selected">
                    <el-checkbox
                      :label="item.id"
                      :class="{
                        'd-none': item.title < 1,
                        'flex-row-reverse': isRtl(),
                        'text-right': isRtl()
                      }"
                      class="mb-2"
                    >
                      {{ item.title }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-collapse>
            </div>
          </el-collapse-item>
        </div>
      </el-collapse>
      <!-- END USER ATTRIBUTES  -->
    </card>

    <!-- Start Share Print -->
    <div class="under_words">
      <div class="icons_wrapping_edit_print">
        <el-tooltip
          class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
          content="Print Member Profile"
          placement="top-start"
        >
          <el-button
            @click="printprofilePage"
            type="success"
            class="mb-2 mr-2 ml-2 edit-btn print-btn "
            ><i class="fa fa-print"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip
          class=" mb-2 mr-2 ml-2 edit-btn print-btn edit_score_button main_tool_tip"
          content="Share Member Profile"
          placement="top-start"
        >
          <el-button
            type="success"
            class="mb-2 "
            @click="open_share_profile_dialog"
            ><i class="fa fa-share-alt icons_actions "></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- End Share Print -->

    <!--// Start share   -->
    <el-dialog
      :title="`${localization('Share personal strategy')}`"
      :visible.sync="share_profile_dialog"
      top="4vh"
      :class="{ share_profile_with_rtl: isRtl() }"
    >
      <el-form
        :model="share_profile_data"
        :rules="share_profile_rules"
        ref="for_add_new_contact"
      >
        <el-form-item
          :label="`${localization('Email')}`"
          prop="email"
          :rules="[
            {
              required: true,
              message: 'Please input email address',
              trigger: 'blur'
            },
            {
              type: 'email',
              message: 'Please input correct email address',
              trigger: ['blur', 'change']
            }
          ]"
        >
          <el-input
            v-model="share_profile_data.email"
            autocomplete="off"
            style="width: 100%"
            :placeholder="`${localization('Email')}`"
            type="email"
          ></el-input>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="
            dialog-footer
            main_dialog_footer
          "
      >
        <el-button
          @click="close_share_profile_dialog"
          class="for_c_buttons text-center text-capitalize"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="
              text-center
              for_c_btns
              text-capitalize

            "
          @click="share_profile_page"
          >{{ localization("Send") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- // End  share  -->
  </div>
</template>

<script>
// import Edit from "./edit.vue";
import Info from "../../components/Cards/Info";
import ForShowUserProfile from "../tasks/ForShowUserProfile.vue";
import Index from "../tasks/index.vue";
import { Progress } from "element-ui";
import Swal from "sweetalert2";
import LAlert from "src/components/Alert";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      score: {},
      score_dialog: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      edit_user_dialog: false,
      edit_tasks_dialog: false,
      selected: [],
      share_profile_dialog: false,
      share_profile_rules: {
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      share_profile_data: {}
    };
  },
  // props: {
  //   selected: []
  // },
  components: {
    [Progress.name]: Progress,
    // Edit,
    Index,
    Info,
    LAlert,
    ForShowUserProfile
  },

  computed: {
    user: function() {
      return this.$store.state.user.user;
    },
    image: function() {
      if (this.user.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.user.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userAttributes() {
      return this.$store.getters["user/attrs"];
    },
    userScorePercentage() {
      // this is for score display as a percentage, if it is not display as percentage on mobile, delete this
      let score = { ...this.userScore };
      for (let n in score) {
        score[n] = Math.floor((score[n] * 100) / 35);
      }
      return score;
    },
    select() {
      this.$emit("select_attribute", this.selected);
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.$route.params.id).then(res => {
      if (this.user.selected_attributes != null) {
        this.selected = this.user.selected_attributes;
      }
    });
  },
  methods: {
    Refresh() {
      // this.$store.dispatch("user/user", this.user_id);
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return "6-12 years";
        case 2:
          return "13 -21 years";
        case 3:
          return "22 or older";
        default:
          return "-";
      }
    },
    save() {
      this.$store
        .dispatch("user/save", { id: `/${this.user.id}`, query: this.score })
        .then(_ => {
          this.Refresh({});
          this.$store.dispatch("user/user", this.$route.params.id);
          this.score_dialog = false;
          Swal.fire({
            text: "Your score has been updated.",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            type: "success"
          });
        });
    },
    open_score_dialog() {
      this.score = { ...this.userScore };
      this.score_dialog = true;
    },
    getHint(key, type) {
      switch (type) {
        // case "Avoid":
        case "Avoid":
          if (key == "Sequence") {
            return `${this.localization("Sequence Avoid")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision Avoid")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR Avoid")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence Avoid")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("First Score Degree")} ${this.localization(
        //   key
        // )}`;
        case "As Needed":
          // case "As Needed":
          if (key == "Sequence") {
            return `${this.localization("Sequence As Needed")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision As Needed")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR As Needed")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence As Needed")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Second Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("as needed")}`;
        // case `${this.localization("Use First")}`:
        case `Use First`:
          if (key == "Sequence") {
            return `${this.localization("Sequence Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence Use First")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Third Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("first")}`;
        case "Sequence":
          // case `${this.localization("Sequence")}`:
          return `${this.localization("Sequence Case")}`;
      }
    },
    printprofilePage() {
      window.print();
    },
    //// share profile page
    share_profile_page() {
      this.share_profile_dialog = true;
      this.$refs["for_add_new_contact"].validate(valid => {
        if (valid) {
          let cards = document.querySelector(".wrapper_for_sharing_tasks");
          html2canvas(cards, {
            logging: true,
            letterRendering: 1,
            allowTaint: true,
            useCORS: true
          }).then(canvas => {
            const payload = new FormData();
            canvas.toBlob(blob => {
              let img_to_blob = new File([blob], "profile.png", {
                type: "image/png"
              });
              payload.append("photo", img_to_blob);
              payload.append("email", this.share_profile_data.email);
              this.$store.dispatch("user/sharing_profile_task", payload);
              Swal.fire({
                title: "",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.share_profile_dialog = false;
              this.share_profile_data.email = "";
            }, "image/png");
          });
        }
      });
    },
    close_share_profile_dialog() {
      this.share_profile_dialog = false;
      this.share_profile_data.email = "";
    },
    open_share_profile_dialog() {
      this.share_profile_dialog = true;
    }
  }
};
</script>

<style scoped lang="scss">
.print-btn {
  background-color: #39c;
  border-color: #39c;
  transition: 0.2s;
  &.item {
    background-color: #f93;
    background-color: #c36;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    color: #fff !important;
    // .link-extention {
    // }
  }
  &:hover {
    opacity: 0.8;
  }
  &.edit_score_button {
    background-color: #3c3;
    border-color: #3c3;
  }
  &.share_score_button {
    background-color: #f93;
    border-color: #f93;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
/* mobile score progress design */
.wrapping_of_some_info {
  .learner_type {
    background: #00563f;
    color: #fff;
    padding: 10px;
    min-width: 130px;
    width: fit-content;
    margin: auto;
    border-radius: 8px;
  }
}
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;
  background-color: #c36;
}
.main_collapse_title_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .every_collapse_title {
    font-size: 1.2rem;
    @media (min-width: 320px) and (max-width: 480px) {
      // font-size: 18px;
      font-size: 1rem;
    }
    @media (min-width: 320px) and (max-width: 400px) {
      // font-size: 11px;
      font-size: 1rem;
    }
  }
  .every_collapse_button {
    color: #fff;
    width: 50px;
    height: 50px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 36px;
      height: 36px;
    }
  }
  &.is_rtl {
    flex-direction: row-reverse;
    padding-right: 10px;
  }
}
.el-collapse {
  border-top: 1px solid #ebeef5;
  border-bottom: 0px;
  background: #fff;
}
::v-deep .el-tooltip__popper {
  background-color: #3c3 !important;
  color: #fff;
}
::v-deep .el-tooltip__popper.is-dark {
  background-color: #3c3 !important;
  color: #fff;
}
.el-tooltip__popper.is-dark {
  background-color: #3c3 !important;
  color: #fff;
}
::v-deep .el-checkbox {
  // text-transform: capitalize !important;
  display: flex;
}
::v-deep .el-checkbox__label {
  // word-break: break-all !important;
  margin-bottom: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  padding-right: 10px;
  font-size: 1.2rem;
  color: #000;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  // color: #9a9a9a;
}
.span_attr_type {
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.alert_for_collapse {
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}

//? start new style
.user_bg_img {
  // background: #3c3;
  height: 150px;
  overflow: unset;

  .new_user_background {
    background: #00563f;
    height: 100%;
    margin: auto;
  }
}
.label {
  /* border-radius: 50%;
  margin: 0.5rem;
  width: 5rem;
  height: 5rem;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.8;
  color: white; */

  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

.letter {
  font-size: 40px;
}

.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.card.score .card-body {
  padding: 0.7rem 0 !important;
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: unset;
}

/* close dialog */
/* ::v-deep
  .el-dialog__wrapper
  .el-dialog
  .el-dialog__headerbtn
  .el-dialog__close {
  display: block;
} */
::v-deep .el-dialog__close {
  display: block !important;
}
.main_words_of_scores {
  font-size: 1.3rem;
  text-transform: capitalize;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.2rem;
  }
}

// ! custom dialog footer buttons
.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
</style>

<style lang="scss">
.for_user_edit_scores_dialog {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.for_user_edit_details_dialog {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 0 !important;
    }
  }
}

.just_for_showing_one {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
  // .card-user .card-body {
  //   overflow: hidden;
  // }
}

.under_words {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  &.is_rtl {
    flex-direction: row-reverse;
  }
  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}
</style>
